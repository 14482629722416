import React from 'react';

const AppAdsTxt = () => {
  return (
    <div>
      <h1>app-ads.txt</h1>
      <pre>
        google.com, pub-7020517006480574, DIRECT, f08c47fec0942fa0
      </pre>
    </div>
  );
};

export default AppAdsTxt;

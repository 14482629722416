import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LandingPage from './components/LandingPage';
import PrivacyPage from './components/PrivacyPage';
import AppAdsTxt from './components/AppAdsTxt';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/app-ads.txt" element={<AppAdsTxt />} />
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;

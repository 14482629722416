import React from "react";
import "./LandingPage.css";
import appleStoreButton from "../assets/apple-store-button.png";
import googlePlayStoreButton from "../assets/play-store-button.png";
import ratokunboMobile from "../assets/ratokunbo-mobile.png";

const StoreButton = ({ storeLink, altText, imgSrc }) => {
  return (
    <a href={storeLink} target="_blank" rel="noopener noreferrer">
      <img src={imgSrc} alt={altText} />
    </a>
  );
};

const LandingPage = () => {
  return (
    <div className="landing-page-container">
      <div className="left-section">
        <img src={ratokunboMobile} alt="Ratokunbo Mobile" className="mobile-image" />
      </div>
      <div className="right-section">
        <h1>Welcome to Your Secondhand Haven</h1>
        <p>Sustainable Shopping, Seamless Selling</p>
        <div className="button-container">
          <StoreButton
            storeLink="https://apps.apple.com/us/app/ratokunbo/id6478985438"
            altText="Download on the App Store"
            imgSrc={appleStoreButton}
          />
          <StoreButton
            storeLink="https://play.google.com/store/apps/details?id=com.ratokunbo.mobile"
            altText="Get it on Google Play"
            imgSrc={googlePlayStoreButton}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
import React from 'react';
import './PrivacyPage.css';

const PrivacyPage = () => {
  return (
    <div className="privacy-page-container">
      <h1>Privacy Policy for Ratokunbo</h1>
      <p><strong>Effective Date:</strong> 24/02/2024</p>
      <p>Welcome to RatoKunbo, the ecommerce platform for purchasing and selling used products in Nigeria. This Privacy Policy is intended to inform you about how we collect, use, and disclose your personal information when you use our mobile application ("App") and related services.</p>
      <p>By accessing or using the RatoKunbo App, you agree to the terms of this Privacy Policy. If you do not agree with any terms herein, please do not use our services.</p>

      <h2>1. Information We Collect</h2>
      <p>a. <strong>Personal Information:</strong> When you sign up for an account on RatoKunbo, we collect information such as your name, email address, phone number, and postal address.</p>
      <p>b. <strong>Transaction Information:</strong> When you make a purchase or sale through our App, we collect information about the transaction, including the products bought or sold, payment details, and shipping information.</p>
      <p>c. <strong>Device Information:</strong> We automatically collect certain information about your device when you use our App, such as device type, operating system, unique device identifiers, and IP address.</p>
      <p>d. <strong>Usage Information:</strong> We collect information about how you interact with our App, including pages visited, time spent on each page, search queries, and features used.</p>

      <h2>2. How We Use Your Information</h2>
      <p>a. <strong>To Provide Services:</strong> We use your personal information to operate, maintain, and improve our services, including facilitating transactions, providing customer support, and personalizing your experience.</p>
      <p>b. <strong>Communication:</strong> We may use your contact information to send you transactional messages, service-related announcements, and promotional offers. You can opt out of promotional communications at any time.</p>
      <p>c. <strong>Analytics:</strong> We analyze usage patterns and trends to understand how our App is used and to optimize performance, user experience, and marketing efforts.</p>
      <p>d. <strong>Legal Compliance:</strong> We may use your information to comply with applicable laws, regulations, or legal processes, and to protect our rights, property, or safety and that of our users and the public.</p>

      <h2>3. How We Share Your Information</h2>
      <p>a. <strong>Service Providers:</strong> We may share your information with third-party service providers who help us operate our business, such as payment processors, shipping carriers, and IT service providers.</p>
      <p>b. <strong>Legal Obligations:</strong> We may disclose your information in response to a legal request if we believe it is required by law, regulation, or legal process, or to protect our rights, property, or safety or that of others.</p>
      <p>c. <strong>Business Transfers:</strong> In the event of a merger, acquisition, reorganization, or sale of assets, your information may be transferred or disclosed as part of the transaction.</p>

      <h2>4. Data Security</h2>
      <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p>

      <h2>5. Your Choices and Rights</h2>
      <p>a. <strong>Account Settings:</strong> You can review and update your account information through the settings in the RatoKunbo App.</p>
      <p>b. <strong>Marketing Communications:</strong> You can opt out of receiving promotional emails by following the instructions provided in the emails. Please note that you cannot opt out of receiving transactional or service-related communications.</p>

      <h2>6. Children's Privacy</h2>
      <p>RatoKunbo is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We reserve the right to update or modify this Privacy Policy at any time. If we make material changes, we will notify you by email or by posting a notice in the App prior to the changes taking effect. Your continued use of the App after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions, concerns, or complaints about this Privacy Policy or our data practices, please contact us at <a href="mailto:info@ratokunbo.com">info@ratokunbo.com</a>.</p>

      <p>By using the RatoKunbo App, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.</p>
    </div>
  );
}

export default PrivacyPage;